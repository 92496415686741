
.pp-img-container {
  position: relative;
  width: 100%;
  min-width: 1px;
  padding-bottom: 30px;
  min-height: 1px;
}

.pp-img {
  border-radius: 2px;
  max-width: 96%;
}

.pp-img-counter {
  position: absolute;
  top: -30px;
  left: 50%;
  margin-left: -15px;
  width: 30px;
  height: 30px;
  font-size: 10px;
  line-height: 30px;
  font-weight: bold;
  opacity: 0.8;
}

.pp-img-container img {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

}
