.solid-overlap {
  position: fixed;
  top: 0;
  bottom: 0;
  visibility: hidden;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: 999;
  overflow: hidden;
}

/* Slide overlap to/from Inao-page & Present-page */
.solid-overlap.slideToInfo,
.solid-overlap.slideToProj {
  visibility: visible;
  opacity: 0;
  -webkit-transition: opacity 0.5s 1.1s;
  transition: opacity 0.5s 1.1s;
  -webkit-animation: op-visibilityOff-animate 0s 1.8s 1 forwards;
          animation: op-visibilityOff-animate 0s 1.8s 1 forwards;
}

@-webkit-keyframes op-visibilityOff-animate {
  100% { visibility: hidden; }
}

@keyframes op-visibilityOff-animate {
  100% { visibility: hidden; }
}

.solid-overlap.slideFromInfo,
.solid-overlap.slideFromProj {
  visibility: visible;
  -webkit-animation: op-visibilityOnOff-animate 0s 1.5s 1 forwards;
          animation: op-visibilityOnOff-animate 0s 1.5s 1 forwards;
}

@-webkit-keyframes op-visibilityOnOff-animate {
  100% { visibility: hidden; }
}

@keyframes op-visibilityOnOff-animate {
  100% { visibility: hidden; }
}

/* Slide  overlap to next/prev Poject */
.solid-overlap.resetAnimation {
  -webkit-animation: none; animation: none;
}

.solid-overlap.slideToPrevProj,
.solid-overlap.slideToNextProj {
  visibility: visible;
  -webkit-animation: op-slideToPrevProj-animate 1.8s 0s 1 forwards;
          animation: op-slideToPrevProj-animate 1.8s 0s 1 forwards;
}

@-webkit-keyframes op-slideToPrevProj-animate {
  0% { visibility: visible; opacity: 1; }
  65% { opacity: 1; }
  99.9% { visibility: visible; }
  100% { visibility: hidden; opacity: 0; }
}

@keyframes op-slideToPrevProj-animate {
  0% { visibility: visible; opacity: 1; }
  65% { opacity: 1; }
  99.9% { visibility: visible; }
  100% { visibility: hidden; opacity: 0; }
}

.overlap-mover {
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  height: 100%;
}

/* Slide op-muver to/from Inao-page & Present-page */
.overlap-mover.slideToInfo {
  left: 100%;
  -webkit-animation: op-muver-slideToInfo-animate 1s 0s 1 forwards;
          animation: op-muver-slideToInfo-animate 1s 0s 1 forwards;
}

@-webkit-keyframes op-muver-slideToInfo-animate {
  0% { left: 100%; }
  28% { left: 3%; }
  100% { left: 0; }
}

@keyframes op-muver-slideToInfo-animate {
  0% { left: 100%; }
  28% { left: 3%; }
  100% { left: 0; }
}

.overlap-mover.slideFromInfo {
  opacity: 0;
  -webkit-animation: op-muver-slideFromInfo-animate 1.4s 0.32s 1 forwards;
          animation: op-muver-slideFromInfo-animate 1.4s 0.32s 1 forwards;
}

@-webkit-keyframes op-muver-slideFromInfo-animate {
  0% { opacity: 0; left: 0; }
  38% { opacity: 1; left: 0;}
  42% { opacity: 1; left: 0;}
  65% { left: 98%; }
  100% { left: 100%; opacity: 1; }
}

@keyframes op-muver-slideFromInfo-animate {
  0% { opacity: 0; left: 0; }
  26% { opacity: 1;  left: 0;}
  28% { opacity: 1; left: 0;}
  65% { left: 98%; }
  100% { left: 100%; opacity: 1; }
}

.overlap-mover.slideToProj {
  -webkit-animation: op-slideToProj-animate 1s 0s 1 forwards;
          animation: op-slideToProj-animate 1s 0s 1 forwards;
}

@-webkit-keyframes op-slideToProj-animate {
  0% {
    left: -100%;
    opacity: 1;
    right: auto;
  }

  30% {
    left: -3%;
  }

  100% {
    left: 0%;
    opacity: 1;
    right: auto;
  }
}

@keyframes op-slideToProj-animate {
  0% {
    left: -100%;
    opacity: 1;
    right: auto;
  }

  30% {
    left: -3%;
  }

  100% {
    left: 0%;
    opacity: 1;
    right: auto;
  }
}

.overlap-mover.slideFromProj {
  opacity: 0;
  -webkit-animation: op-slideFromProj-animate 1s 0.32s 1 forwards;
          animation: op-slideFromProj-animate 1s 0.32s 1 forwards;
}

@-webkit-keyframes op-slideFromProj-animate {
  0% { opacity: 0; left: 0; }
  38% { opacity: 1; left: 0;}
  42% { opacity: 1; left: 0;}
  65% { left: 98%; }
  100% { left: 100%; opacity: 1; }
}

@keyframes op-slideFromProj-animate {
  0% { opacity: 0; left: 0; }
  26% { opacity: 1;  left: 0;}
  28% { opacity: 1; left: 0;}
  65% { left: 98%; }
  100% { left: 100%; opacity: 1; }
}

/* Slide  op-mover to next/prev Poject */
.overlap-mover.resetAnimation {
  -webkit-animation: none;
          animation: none;
  opacity: 1;
  right: auto;
}

.overlap-mover.slideToPrevProj {
  -webkit-animation: op-mover-slideToPrevProj-animate 1s 0s 1 forwards;
          animation: op-mover-slideToPrevProj-animate 1s 0s 1 forwards;
}

@-webkit-keyframes op-mover-slideToPrevProj-animate {
  0% {
    left: -100%;
    opacity: 1;
    right: auto;
  }

  30% {
    left: -3%;
  }

  100% {
    left: 0%;
    opacity: 1;
    right: auto;
  }
}

@keyframes op-mover-slideToPrevProj-animate {
  0% {
    left: -100%;
    opacity: 1;
    right: auto;
  }

  30% {
    left: -3%;
  }

  100% {
    left: 0%;
    opacity: 1;
    right: auto;
  }
}

.overlap-mover.slideToNextProj {
  -webkit-animation: op-mover-slideToNextProj-animate 1s 0s 1 forwards;
          animation: op-mover-slideToNextProj-animate 1s 0s 1 forwards;
}

@-webkit-keyframes op-mover-slideToNextProj-animate {
  0% {
    right: -100%;
    opacity: 1;
    left: auto;
  }

  28% {
    right: -3%;
  }

  100% {
    right: 0%;
    opacity: 1;
    left: auto;
  }
}

@keyframes op-mover-slideToNextProj-animate {
  0% {
    right: -100%;
    opacity: 1;
    left: auto;
  }

  28% {
    right: -3%;
  }

  100% {
    right: 0%;
    opacity: 1;
    left: auto;
  }
}

/* Overlap colorise div's styles */
.overlap-old-color {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
}

.overlap-new-color {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

/* Swith opacity to colorise div's */
.overlap-new-color.slideToInfo,
.overlap-new-color.slideToProj {
  -webkit-animation: overlap-color-animate1 0.24s 0s 1 forwards;
          animation: overlap-color-animate1 0.24s 0s 1 forwards;
}

@-webkit-keyframes overlap-color-animate1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes overlap-color-animate1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.overlap-new-color.slideFromInfo,
.overlap-new-color.slideFromProj {
  -webkit-animation: overlap-color-animate2 0.24s 0s 1 forwards;
          animation: overlap-color-animate2 0.24s 0s 1 forwards;
}

@-webkit-keyframes overlap-color-animate2 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes overlap-color-animate2 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.overlap-new-color.slideToPrevProj {
  -webkit-transition: opacity 0.24s;
  transition: opacity 0.24s;
  opacity: 1;
}

.overlap-new-color.slideToNextProj {
  -webkit-transition: opacity 0.24s;
  transition: opacity 0.24s;
  opacity: 1;
}
