.to-next-button {
  position: fixed;
  cursor: pointer;
  margin: -40px -600px 0 0;
  top: 50%;
  right: 50%;
  width: 100px;
  height: 80px;
  overflow: hidden;
}

.to-next-button.lessWidth1200 {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  right: 0;
  top: -20px;
}

.to-next-button svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.tnext-button-arrow {
  transform-origin: 50%;
  -webkit-transform-origin: 50% 50%;
}

.tnext-button-arrow.roll {
  -webkit-animation: tnb-arrow-roll-animate 1s 0s infinite;
          animation: tnb-arrow-roll-animate 1s 0s infinite;
}

@-webkit-keyframes tnb-arrow-roll-animate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1;
  }

  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    opacity: 1
  }
}

@keyframes tnb-arrow-roll-animate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1;
  }

  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    opacity: 1
  }
}

.tnext-button-circle {
  opacity: 0;
  transform-origin: 50%;
  -webkit-transform-origin: 50% 50%;
}

.tnext-button-circle.roll {
  -webkit-animation: tnb-circle-roll-animate 1s 0s infinite;
          animation: tnb-circle-roll-animate 1s 0s infinite;
}

@-webkit-keyframes tnb-circle-roll-animate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 0;
  }

  50% {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    opacity: 0;
  }
}

@keyframes tnb-circle-roll-animate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 0;
  }

  50% {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    opacity: 0;
  }
}
