.mini-logos-fly{
  position: absolute;
  width: 700px;
  height: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -520px);
  background: rgba(255,255,255,0);
}

.mlf-svg, .mlf-svg-froze{
  position: absolute;
  width: 100px;
  height: 80px;
  top: 0;
  left: 50%;
}

.mlf-svg g, .mlf-svg-froze g  {
 transform-origin: 50px 62px;
}

.mini-logos-fly.hide .mlf-svg {
 transform-origin: 50px 62px;
 animation: mini-logos-fly-animate-1 1s 0s 1;
}
@keyframes mini-logos-fly-animate-1 {
 0%{left:50%}
 100%{left:100%}
}

.mlf-svg:nth-child(1) {
  top:10px;
  margin-left: -140px;
  animation: mlf-animate-1 2s 0.6s 1 forwards;
  opacity:0;
}
@keyframes mlf-animate-1 {
 0% { transform: scale(1) rotate(-10deg) translate(-20px, -100px);}
 20% { transform: scale(1) rotate(-10deg) translate(-20px, -100px); opacity: 0; }
 50% { transform: scale(0.85) rotate(-10deg) translate(0); opacity:1; }
 100% { transform: scale(0.8) rotate(-12deg) translate(0); opacity:1; }
}

.mlf-svg:nth-child(2) {
  top:110px;
  margin-left: 100px;
  animation: mlf-animate-2 1.4s 0.5s 1 forwards;
  opacity: 0;
}
@keyframes mlf-animate-2 {
 0% {transform: scale(1) rotate(60deg) translate(-20px, -100px);}
 70% {opacity:1; }
 100% {transform: scale(0.4) rotate(45deg) translate(0); opacity:1; }
}

.mlf-svg:nth-child(3) {
  top: 100px;
  margin-left: -250px;
  animation: mlf-animate-3 1s 0.6s 1 forwards;
  opacity: 0;
}
@keyframes mlf-animate-3 {
 0% { transform: scale(1) rotate(-60deg) translate(50px, -100px); }
 60% { transform: scale(0.7) rotate(-50deg) translate(20px, -20px); opacity:1;}
 100% {transform: scale(0.6) rotate(-40deg) translate(0); opacity:1; }
}

.mlf-svg:nth-child(4) {
  top: 20px;
  margin-left: 100px;
  animation: mlf-animate-4 1s 0.5s 1 forwards;
  opacity: 0;
}
@keyframes mlf-animate-4 {
 0% { transform: scale(1) rotate(42deg) translate(50px, -200px); }
 100% {transform: scale(0.6) rotate(35deg) translate(0); opacity:1; }
}

.mlf-svg:nth-child(5) {
  top:110px;
  margin-left: -150px;
  animation: mlf-animate-5 1s 0.3s 1 forwards;
  opacity: 0;
}
@keyframes mlf-animate-5 {
 0% { transform: scale(2) rotate(10deg) translate(-100px, -200px); }
 30% { opacity:1; }
 55% { transform: scale(1) rotate(-15deg) translate(-5px, -10px); opacity:1; }
 100% {transform: scale(0.8) rotate(-10deg) translate(0); opacity:1; }
}

.mlf-svg:nth-child(6) {
  top:40px;
  margin-left: 60px;
  transform: scale(1) rotate(8deg);
  animation: mlf-animate-6 1s 0.4s 1 forwards;
  opacity: 0;
}
@keyframes mlf-animate-6 {
 0% { transform: scale(0.88) rotate(20deg) translate(200px, -100px); }
 40% { opacity:1; }
 60% { transform: scale(0.98) rotate(10deg) translate(20px, -4px); opacity:1; }
 100% {transform: scale(1) rotate(8deg) translate(0); opacity:1; }
}

.mlf-svg:nth-child(7) {
  top:-20px;
  margin-left: -20px;
  animation: mlf-animate-7 0.8s 0.55s 1 forwards;
  opacity: 0;
}
@keyframes mlf-animate-7 {
 0% { transform: scale(0.3) rotate(-10deg) translate(-19px, -100px); }
 40% { opacity:1; }
 60% { transform: scale(0.78) rotate(4deg) translate(4px, -4px); opacity:1; }
 100% {transform: scale(0.84) rotate(8deg) translate(0); opacity:1; }
}

.mlf-svg:nth-child(8) {
  top:100px;
  margin-left: 250px;
  transform: scale(0.8) rotate(15deg);
  animation: mlf-animate-8 0.6s 0.7s 1 forwards;
  opacity: 0;
}
@keyframes mlf-animate-8 {
 0% { transform: translateX(-40px) scale(0.3) rotate(15deg); opacity: 0;}
 60% { opacity:0.4; }
 100% {transform: translateX(0) scale(0.7) rotate(15deg); opacity:0.4; }
}

.mlf-svg:nth-child(9) {
  top: 50px;
  margin-left: -300px;
  transform: scale(0.8) rotate(-15deg);
  animation: mlf-animate-9 0.6s 0.7s 1 forwards;
  opacity: 0;
}
@keyframes mlf-animate-9 {
 0% { transform: translateX(40px) scale(0.2) rotate(-15deg); opacity: 0;}
 60% { opacity:0.4; }
 100% {transform: translateX(0) scale(0.5) rotate(-15deg); opacity:0.4; }
}
