.logo-life {
  position: absolute;
  top: 0px;
  left: 50%;
  width: 100px;
  height: 20px;
  margin-left: -50px;
  overflow: hidden;
  border-radius: 1px;
  background-color: #000000;
  opacity: 0;
}

.logo-life.show {
  opacity: 1; transition: opacity 0.4s;
}

.logo-life.hide {
  opacity: 0; transition: opacity 0.3s;
}

.logo-life > div {
  display: inline-block;
  margin: 0 4px;
  top: 0;
  left: 0;
  width: 7px;
  height: 7px;
  opacity: 1;
  border-radius: 1px;
  transform-origin: 50%;
  transform: rotate(45deg);
}
