html::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  display: none;
}
::selection {background:rgba(154,38,39,0.4); text-shadow:none} img{max-width:100%}
.iosfix {height: 101%; overflow: hidden;}

* {
  padding: 0;
  margin: 0;
  font-weight: lighter;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 16px;
  color: #e2e2e2;
  text-decoration: none;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

html {
  min-height: 100%; width: 100%;
}

body {
  position: relative;
  width: 100%;
  min-width: 300px;
  height: auto;
  min-height: 100%;
  min-height: 100vh;
  background-color: #000;
}

.main-content {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  max-width: 1200px;
  min-height: 100%;
  overflow: hidden;
  padding: 10px 0 140px;
}
