.main-background {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
  height: 100%;
  overflow: hidden;
}

.main-background> :nth-child(1) {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
  height: 100%;
}

.black-planet-1 {
  position: absolute;
  width: 1920px;
  height: 1434px;
  top: 0;
  left: 50%;
  margin-left: -960px;
  background: url(./media/black-planet-1.jpg) center top no-repeat #000;
}
