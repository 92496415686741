.main-txt-lines {
  position: relative;
  margin: 0 auto;
  top: 0;
  width: 100%;
  max-width: 700px;
  height: 36px;
}
.main-txt-lines > div {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 1005;
}

.main-txt-lines.lessWidth600 {
  width: 60%;
}

.main-txt-lines svg {
  position: absolute;
}
.main-txt-lines g {
  opacity: 0;
}

.main-txt-lines g.play {
  -webkit-animation-play-state: running;
  animation-play-state: running ;
}

.main-txt-lines g.pause {
  -webkit-animation-play-state: paused ;
  animation-play-state: paused;
}

.mt-line-1 {
  width: 50%;
  right:50%;
  margin-right: 3px;
  top: 0;
}
.mt-line-1 g.firstAnimate {
  transform-origin: 100%;
  animation: mt-line-animate-1 1.4s 0s 1 forwards;
  opacity: 1;
}
@keyframes mt-line-animate-1 {
  0%{ transform: translateX(100%); opacity: 1; }
  40%{ transform: translateX(4%); }
  100%{ transform: translateX(0); opacity: 1; }
}
.mt-line-1 g.loopAnimate {
 transform: translateX(0); opacity: 1;
}

.mt-line-2 {
  width: 20px;
  left: 32px;
  top: 0;
  transform-origin: 100% 0;
  transform: rotate(-45deg);
}
.mt-line-2 g.firstAnimate {
  transform-origin: 100%;
  animation: mt-line-animate-2 1s 0.3s 1 forwards;
}
@keyframes mt-line-animate-2 {
  0%{ transform: translateX(100%); opacity: 1; }
  25%{ transform: translateX(0); }
  30% { opacity: 1; }
  31% { opacity: 0; }
  33% { opacity: 1; }
  35% { opacity: 0.3; }
  36% { opacity: 0.8; }
  100%{ transform: translateX(-100%); opacity: 0; }
}

.mt-line-2a {
  width: 26px;
  left: 13px;
  top: 14px;
}
.mt-line-2a g.firstAnimate {
  transform-origin: 100%;
  animation: mt-line-animate-2a 0.8s 0.4s 1 forwards;
}
@keyframes mt-line-animate-2a {
  0%{ transform: translateX(100%); opacity: 1; }
  45%{ transform: translateX(0); }
  100%{ transform: translateX(-100%); opacity: 1; }
}

.mt-line-2b {
  width: 15px;
  left: -3px;
  transform-origin: 100% 0;
  transform: rotate(-45deg);
  top: 14px;
}
.mt-line-2b g.firstAnimate {
  transform-origin: 100%;
  animation: mt-line-animate-2b 1s 0.85s 1 forwards;
}
@keyframes mt-line-animate-2b {
  0%{ transform: translateX(100%); opacity: 1; }
  100%{ transform: translateX(-100%); opacity: 1; }
}

.mt-line-3 {
  width: 50%;
  left: 50%;
  margin-left:-1px;
  top: 0;
}

.mt-line-3 g.firstAnimate {
  transform-origin: 0;
  animation: mt-line-animate-3 2s 0s 1 forwards;

}
@keyframes mt-line-animate-3 {
  0%{ transform: translateX(-100%); opacity: 1; }
  40%{ transform: translateX(-6%); }
  30% { opacity: 1; }
  31% { opacity: 0.5; }
  36% { opacity: 1; }
  37% { opacity: 0.2; }
  38% { opacity: 1; }
  100%{ transform: translateX(0); opacity: 1;  }
}
.mt-line-3 g.loopAnimate {
 transform: translateX(0); opacity: 1;
}

.mt-line-4 {
  transform-origin: 0;
  transform: rotate(-45deg);
  width: 16px;
  right: 24px;
  top: 0;
}
.mt-line-4 g.firstAnimate {
  transform-origin: 0;
  animation: mt-line-animate-4 1s 0.5s 1 forwards;
}
@keyframes mt-line-animate-4 {
  0%{ transform: translateX(-100%); opacity: 1; }
  25%{ transform: translateX(0); }
  36% { opacity: 0.8; }
  100%{ transform: translateX(100%); opacity: 0; }
}
.mt-line-4a {
  width: 29px;
  right: 0px;
  top: -11px;
}
.mt-line-4a g.firstAnimate {
  transform-origin: 100%;
  animation: mt-line-animate-4a 0.8s 0.64s 1 forwards;
}
@keyframes mt-line-animate-4a {
  0%{ transform: translateX(-100%); opacity: 1; }
  45%{ transform: translateX(0); }
  100%{ transform: translateX(100%); opacity: 1; }
}
.mt-line-5 {
  width: 20px;
  left: 100px;
  top: 0;
  transform-origin: 100% 0;
  transform: rotate(45deg);
}

.mt-line-5 g.firstAnimate {
  transform-origin: 100%;
  animation: mt-line-animate-2 1s 0.2s 1 forwards;
}

.mt-line-5a {
  width: 65px;
  left: 40px;
  top: -14px;
}

.mt-line-5a g.firstAnimate {
  transform-origin: 100%;
  animation: mt-line-animate-2a 0.8s 0.34s 1 forwards;
}

.mt-line-6 {
  width: 20px;
  right: 150px;
  top: 0;
  transform-origin: 0;
  transform: rotate(45deg);
}
.mt-line-6 g.firstAnimate {
  transform-origin: 0;
  animation: mt-line-animate-6 1s 0.3s 1 forwards;
}
@keyframes mt-line-animate-6 {
  0%{ transform: translateX(-100%); opacity: 1; }
  25%{ transform: translateX(0); }
  36% { opacity: 0.8; }
  100%{ transform: translateX(100%); opacity: 0; }
}

.mt-line-6a {
  width: 90px;
  right: 66px;
  top: 14px;
}
.mt-line-6a g.firstAnimate {
  transform-origin: 100%;
  animation: mt-line-animate-6a 1.2s 0.6s 1 forwards;
}
@keyframes mt-line-animate-6a {
  0%{ transform: translateX(-100%); opacity: 1; }
  30%{ transform: translateX(0); }
  40% { opacity: 1; }
  41% { opacity: 0; }
  46% { opacity: 1; }
  80%{ transform: translateX(94%); }
  100%{ transform: translateX(100%); opacity: 0; }
}

.mt-line-7 {
  width: 3px;
  height: 12px;
  right: 50%;
  top: -5px;
  opacity: 1;
}
.mt-line-7 g.firstAnimate {
  opacity: 1;
  transform-origin: 0;
  animation: mt-line-animate-7 0.5s 0s 1 forwards;
}
@keyframes mt-line-animate-7 {
  0%{ transform: translateY(-100%); opacity: 1; }
  20%{ transform: translateY(100%); }
  20.1%{ transform: translateY(-100%); }
  100%{ transform: translateY(0); opacity: 1; }
}
.mt-line-7 g.loopAnimate {
 transform: translateY(0); opacity: 1;
}
