.reacted-remark {
  position: absolute;
  width: 170px;
  height: 80px;
  top: 26px;
  left: 30px;
  z-index: 200;
  overflow: hidden;
}

.reacted-remark.hide {
  -webkit-animation: reacted-hide-animate-1 0s 3s 1 forwards;
          animation: reacted-hide-animate-1 0s 3s 1 forwards;
}
@-webkit-keyframes reacted-hide-animate-1 {
  100% { visibility: hidden; }
}
@keyframes reacted-hide-animate-1 {
  100% { visibility: hidden; }
}

.reacted-remark.lessWidth500 {
  top: 4px;
  left: 0;
  width: 110px;
}

.rm-text-1 {
  position: absolute;
  width: 50px;
  height: 30px;
  top: 50%;
  left: 16px;
  color: #cdcdcd;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  opacity: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.rm-text-1.lessWidth500 {
  letter-spacing: 1px;
}

.rm-text-1.show {
  -webkit-animation: react-text-animate-1 1.5s 0s 1 forwards linear; animation: react-text-animate-1 1.5s 0s 1 forwards linear;
}

@-webkit-keyframes react-text-animate-1 { 0% { opacity: 0; } 100% { opacity: 1; } }
@keyframes react-text-animate-1 { 0% { opacity: 0; } 100% { opacity: 1; } }

.rm-text-1.hide {
  -webkit-animation: react-text-animate-2 3s 0s 1 forwards linear; animation: react-text-animate-2 3s 0s 1 forwards linear;
}

@-webkit-keyframes react-text-animate-2 { 0% { opacity: 1; } 20% { opacity: 0.8; }  24% { opacity: 0.1; }  24.5% { opacity: 1; }  26% { opacity: 0; }  30% { opacity: 0.5; }  100% { opacity: 0; }}
@keyframes react-text-animate-2 { 0% { opacity: 1; }  20% { opacity: 0.8; }  24% { opacity: 0.1; }  24.5% { opacity: 1; }  26% { opacity: 0; }  30% { opacity: 0.5; }  100% { opacity: 0; }}

.react-ico {
  position: absolute;
  width: 180px;
  height: 80px;
  top: 0;
  left: 0;
  opacity: 0;
}

.react-ico.lessWidth500 {
  left: -2px;
}

.react-ico.show {
  -webkit-transform-origin: 50%; transform-origin: 50%;
  -webkit-animation: react-ico-animate-1 1s 0s 1 forwards linear; animation: react-ico-animate-1 1s 0s 1 forwards linear;
}

@-webkit-keyframes react-ico-animate-1 {
  0% { -webkit-transform: scale(0.8) rotate(-90deg); transform: scale(0.8) rotate(-90deg); opacity: 0; }
  60% { -webkit-transform: scale(0.8) rotate(-90deg); transform: scale(0.8) rotate(-90deg); opacity: 0; }
  90% { -webkit-transform: scale(1) rotate(-10deg); transform: scale(1) rotate(-10deg); opacity: 1; }
  100% { -webkit-transform: scale(1) rotate(0); transform: scale(1) rotate(0); opacity: 1; }
}

@keyframes react-ico-animate-1 {
  0% { -webkit-transform: scale(0.8) rotate(-90deg); transform: scale(0.8) rotate(-90deg); opacity: 0; }
  60% { -webkit-transform: scale(0.8) rotate(-90deg); transform: scale(0.8) rotate(-90deg); opacity: 0; }
  90% { -webkit-transform: scale(1) rotate(-10deg); transform: scale(1) rotate(-10deg); opacity: 1; }
  100% { -webkit-transform: scale(1) rotate(0); transform: scale(1) rotate(0); opacity: 1; }
}

.react-ico.hide {
  -webkit-transform-origin: 50%; transform-origin: 50%;
  -webkit-animation: react-ico-animate-1a 3s 0s 1 forwards linear; animation: react-ico-animate-1a 3s 0s 1 forwards linear;
}

@-webkit-keyframes react-ico-animate-1a {
  0% { -webkit-transform: rotate(0);   transform: rotate(0);opacity: 1; }
  2% { -webkit-transform: rotate(-10deg);   transform: rotate(-10deg); }
  20% { -webkit-transform: rotate(-10deg); transform: rotate(-10deg); }
  22% { -webkit-transform: rotate(-25deg); transform: rotate(-25deg); }
  40% { -webkit-transform: rotate(-25deg); transform: rotate(-25deg); }
  42% { -webkit-transform: rotate(-60deg); transform: rotate(-60deg); opacity: 0.4; }
  44% { opacity: 0.1; } 44.5% { opacity: 1; } 46% { opacity: 0; } 50% { opacity: 0.3; }
  60% { -webkit-transform: rotate(-60deg); transform: rotate(-60deg); }
  62% { -webkit-transform: rotate(-90deg); transform: rotate(-90deg); }
  80% { -webkit-transform: rotate(-90deg); transform: rotate(-90deg); }
  82% { -webkit-transform: rotate(-135deg); transform: rotate(-135deg); }
  100% { -webkit-transform: rotate(-135deg); transform: rotate(-135deg); opacity: 0; }
}

@keyframes react-ico-animate-1a {
  0% { -webkit-transform: rotate(0); transform: rotate(0); opacity: 1; }
  2% { -webkit-transform: rotate(-10deg); transform: rotate(-10deg); }
  20% { -webkit-transform: rotate(-10deg); transform: rotate(-10deg); }
  22% { -webkit-transform: rotate(-25deg); transform: rotate(-25deg); }
  40% { -webkit-transform: rotate(-25deg); transform: rotate(-25deg); }
  42% { -webkit-transform: rotate(-60deg); transform: rotate(-60deg); opacity: 0.4; }
  44% { opacity: 0.1; }
  44.5% { opacity: 1; }
  46% { opacity: 0; }
  50% { opacity: 0.3; }
  60% { -webkit-transform: rotate(-60deg); transform: rotate(-60deg); }
  62% { -webkit-transform: rotate(-90deg); transform: rotate(-90deg); }
  80% { -webkit-transform: rotate(-90deg); transform: rotate(-90deg); }
  82% { -webkit-transform: rotate(-135deg); transform: rotate(-135deg); }
  100% { -webkit-transform: rotate(-135deg); transform: rotate(-135deg); opacity: 0; }
}

.rm-lines {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.rm-lines.lessWidth500 {
  visibility: hidden;
}

.rm-lines>div {
  position: absolute;
}

.rm-lines>div>svg {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.rm-line-1 {
  width: 42px;
  height: 3px;
  top: 39px;
  left: 104px;
}

.rm-line-2 {
  width: 22px;
  height: 3px;
  top: 34px;
  left: 98px;
}

.rm-line-2a {
  width: 18px;
  height: 3px;
  top: 35px;
  left: 119px;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.rm-line-2b {
  width: 22px;
  height: 3px;
  top: 22px;
  left: 132px;
}

.rm-line-3 {
  width: 22px;
  height: 3px;
  top: 44px;
  left: 98px;
}

.rm-line-3a {
  width: 18px;
  height: 3px;
  top: 44px;
  left: 120px;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.rm-line-3b {
  width: 22px;
  height: 3px;
  top: 56px;
  left: 132px;
}

.rm-line-4 {
  width: 3px;
  height: 12px;
  top: 52px;
  left: 92px;
}

.rm-line-4a {
  width: 3px;
  height: 14px;
  top: 64px;
  left: 92px;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.rm-line-5 {
  width: 3px;
  height: 12px;
  top: 52px;
  left: 86px;
}

.rm-line-5a {
  width: 3px;
  height: 22px;
  top: 63px;
  left: 86px;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.rm-line-6 {
  width: 3px;
  height: 28px;
  top: 47px;
  left: 97px;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.rm-line-6a {
  width: 3px;
  height: 22px;
  top: 66px;
  left: 116px;
}

.rm-line-7 {
  width: 3px;
  height: 22px;
  top: 6px;
  left: 86px;
}

.rm-line-8 {
  width: 3px;
  height: 12px;
  top: 16px;
  left: 92px;
}

.rm-line-8a {
  width: 3px;
  height: 18px;
  top: 15px;
  left: 88px;
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
}

.rm-line-9 {
  width: 3px;
  height: 28px;
  top: 38px;
  left: 79px;
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}

.rm-lines g {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.rm-line-1.show g {
  -webkit-animation: rm-line-animate-1 1s 0s 1 forwards; animation: rm-line-animate-1 1s 0s 1 forwards;
}

@-webkit-keyframes rm-line-animate-1 {
  0% { -webkit-transform: translateX(100%) scaleX(0.4); transform: translateX(100%) scaleX(0.4); }
  20% { -webkit-transform: translateX(-60%) scaleX(0.4); transform: translateX(-60%) scaleX(0.4); }
  20.1% { -webkit-transform: translateX(100%); transform: translateX(100%); }
  60% { opacity: 1; }
  60.5% {opacity: 0; }
  68% { opacity: 1; }
  68.5% {-webkit-transform: translateX(10%); transform: translateX(10%); }
  70% { -webkit-transform: translateX(0); transform: translateX(0); opacity: 1; }
  90% { -webkit-transform: translateX(-40%); transform: translateX(-40%); }
  100% { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
}

@keyframes rm-line-animate-1 {
  0% { -webkit-transform: translateX(100%) scaleX(0.4); transform: translateX(100%) scaleX(0.4);}
  20% { -webkit-transform: translateX(-60%) scaleX(0.4); transform: translateX(-60%) scaleX(0.4);}
  20.1% { -webkit-transform: translateX(100%); transform: translateX(100%);}
  60% { opacity: 1; }
  60.5% { opacity: 0; }
  68% { opacity: 1; }
  68.5% { -webkit-transform: translateX(10%); transform: translateX(10%); }
  70% { -webkit-transform: translateX(0); transform: translateX(0); opacity: 1; }
  90% { -webkit-transform: translateX(-40%); transform: translateX(-40%); }
  100% { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
}

.rm-line-2.show g { -webkit-animation: rm-line-animate-2 2s 0s 1 forwards; animation: rm-line-animate-2 2s 0s 1 forwards; }

@-webkit-keyframes rm-line-animate-2 {
  30% { -webkit-transform: translateX(100%); transform: translateX(100%); }
  50% { -webkit-transform: translateX(0); transform: translateX(0); }
  70% { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
  100% { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
}

@keyframes rm-line-animate-2 {
  30% { -webkit-transform: translateX(100%); transform: translateX(100%); }
  50% { -webkit-transform: translateX(0); transform: translateX(0); }
  70% { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
  100% { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
}

.rm-line-2a.show g { -webkit-animation: rm-line-animate-2a 2s 0s 1 forwards; animation: rm-line-animate-2a 2s 0s 1 forwards; }

@-webkit-keyframes rm-line-animate-2a {
  30% { -webkit-transform: translateX(100%); transform: translateX(100%); }
  40% { -webkit-transform: translateX(0); transform: translateX(0); }
  50% { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
  100% { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
}

@keyframes rm-line-animate-2a {
  30% { -webkit-transform: translateX(100%); transform: translateX(100%); }
  40% { -webkit-transform: translateX(0); transform: translateX(0); }
  50% { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
  100% { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
}

.rm-line-2b.show g { -webkit-animation: rm-line-animate-2b 1s 0s 1 forwards; animation: rm-line-animate-2b 1s 0s 1 forwards; }

@-webkit-keyframes rm-line-animate-2b {
  0% { -webkit-transform: translateX(100%) scaleX(0.4); transform: translateX(100%) scaleX(0.4); }

  40% {
    opacity: 1;
  }

  40.5% {
    opacity: 0;
  }

  48% {
    opacity: 1;
  }

  68.5% {
    opacity: 0;
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
  }

  70% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }

  90% {
    -webkit-transform: translateX(-40%);
            transform: translateX(-40%);
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes rm-line-animate-2b {
  0% {
    -webkit-transform: translateX(100%) scaleX(0.4);
            transform: translateX(100%) scaleX(0.4);
  }

  40% {
    opacity: 1;
  }

  40.5% {
    opacity: 0;
  }

  48% {
    opacity: 1;
  }

  68.5% {
    opacity: 0;
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
  }

  70% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }

  90% {
    -webkit-transform: translateX(-40%);
            transform: translateX(-40%);
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.rm-line-3.show g {
  -webkit-animation: rm-line-animate-3 2s 0s 1 forwards;
          animation: rm-line-animate-3 2s 0s 1 forwards;
}

@-webkit-keyframes rm-line-animate-3 {
  22% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  40% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }

  40.5% {
    opacity: 0;
  }

  44% {
    opacity: 1;
  }

  80% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes rm-line-animate-3 {
  22% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  40% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }

  40.5% {
    opacity: 0;
  }

  44% {
    opacity: 1;
  }

  80% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.rm-line-3a.show g {
  -webkit-animation: rm-line-animate-3a 1s 0s 1 forwards;
          animation: rm-line-animate-3a 1s 0s 1 forwards;
}

@-webkit-keyframes rm-line-animate-3a {
  30% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  60% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  90% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes rm-line-animate-3a {
  30% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  60% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  90% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.rm-line-3b.show g {
  -webkit-animation: rm-line-animate-3b 1s 0s 1 forwards;
          animation: rm-line-animate-3b 1s 0s 1 forwards;
}

@-webkit-keyframes rm-line-animate-3b {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%)
  }

  40% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }

  70% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }

  90% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes rm-line-animate-3b {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%)
  }

  40% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }

  70% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }

  90% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.rm-line-4.show g {
  -webkit-animation: rm-line-animate-4 1.2s 0s 1 forwards;
          animation: rm-line-animate-4 1.2s 0s 1 forwards;
}

@-webkit-keyframes rm-line-animate-4 {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  20% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@keyframes rm-line-animate-4 {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  20% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.rm-line-4a.show g {
  -webkit-animation: rm-line-animate-4a 1.2s 0s 1 forwards;
          animation: rm-line-animate-4a 1.2s 0s 1 forwards;
}

@-webkit-keyframes rm-line-animate-4a {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  30% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  60% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@keyframes rm-line-animate-4a {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  30% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  60% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.rm-line-5.show g {
  -webkit-animation: rm-line-animate-5 1.2s 0s 1 forwards;
          animation: rm-line-animate-5 1.2s 0s 1 forwards
}

@-webkit-keyframes rm-line-animate-5 {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  30% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  58% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@keyframes rm-line-animate-5 {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  30% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  58% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.rm-line-5a.show g {
  -webkit-animation: rm-line-animate-5a 1.2s 0s 1 forwards;
          animation: rm-line-animate-5a 1.2s 0s 1 forwards;
}

@-webkit-keyframes rm-line-animate-5a {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  10% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  40% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }

  40.5% {
    opacity: 0;
  }

  44% {
    opacity: 1;
  }

  70% {
    -webkit-transform: translateY(-90%);
            transform: translateY(-90%);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 0;
  }
}

@keyframes rm-line-animate-5a {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  10% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  40% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }

  40.5% {
    opacity: 0;
  }

  44% {
    opacity: 1;
  }

  70% {
    -webkit-transform: translateY(-90%);
            transform: translateY(-90%);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 0;
  }
}

.rm-line-6.show g {
  -webkit-animation: rm-line-animate-6 1.2s 0s 1 forwards;
          animation: rm-line-animate-6 1.2s 0s 1 forwards;
}

@-webkit-keyframes rm-line-animate-6 {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  20% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  80% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  80.1% {
    -webkit-transform: translateY(100%) scaleY(0.6);
            transform: translateY(100%) scaleY(0.6);
  }

  100% {
    -webkit-transform: translateY(-100%) scaleY(0.6);
            transform: translateY(-100%) scaleY(0.6);
  }
}

@keyframes rm-line-animate-6 {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  20% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  80% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  80.1% {
    -webkit-transform: translateY(100%) scaleY(0.6);
            transform: translateY(100%) scaleY(0.6);
  }

  100% {
    -webkit-transform: translateY(-100%) scaleY(0.6);
            transform: translateY(-100%) scaleY(0.6);
  }
}

.rm-line-6a.show g {
  -webkit-animation: rm-line-animate-6a 1.2s 0s 1 forwards;
          animation: rm-line-animate-6a 1.2s 0s 1 forwards;
}

@-webkit-keyframes rm-line-animate-6a {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%)
  }

  10% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%)
  }

  60% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@keyframes rm-line-animate-6a {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%)
  }

  10% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%)
  }

  60% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.rm-line-7.show g {
  -webkit-animation: rm-line-animate-7 1.2s 0s 1 forwards;
          animation: rm-line-animate-7 1.2s 0s 1 forwards;
}

@-webkit-keyframes rm-line-animate-7 {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  20% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  70% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  70.1% {
    -webkit-transform: translateY(-100%) scaleY(0.6);
            transform: translateY(-100%) scaleY(0.6);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(100%) scaleY(0.8);
            transform: translateY(100%) scaleY(0.8);
    opacity: 0.4;
  }
}

@keyframes rm-line-animate-7 {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  20% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  70% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  70.1% {
    -webkit-transform: translateY(-100%) scaleY(0.6);
            transform: translateY(-100%) scaleY(0.6);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(100%) scaleY(0.8);
            transform: translateY(100%) scaleY(0.8);
    opacity: 0.4;
  }
}

.rm-line-8.show g {
  -webkit-animation: rm-line-animate-8 2s 0s 1 forwards;
          animation: rm-line-animate-8 2s 0s 1 forwards;
}

@-webkit-keyframes rm-line-animate-8 {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  30% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  90% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

@keyframes rm-line-animate-8 {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  30% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  90% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.rm-line-8a.show g {
  -webkit-animation: rm-line-animate-8a 2s 0s 1 forwards;
          animation: rm-line-animate-8a 2s 0s 1 forwards;
}

@-webkit-keyframes rm-line-animate-8a {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  20% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  50% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@keyframes rm-line-animate-8a {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  20% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  50% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.rm-line-9.show g {
  -webkit-animation: rm-line-animate-9 1.6s 0s 1 forwards;
          animation: rm-line-animate-9 1.6s 0s 1 forwards;
}

@-webkit-keyframes rm-line-animate-9 {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@keyframes rm-line-animate-9 {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.rm-eclipse {
  position: absolute;
  width: 180px;
  height: 80px;
  top: 0;
  left: 0;
}

.rm-eclipse.show g:nth-child(1) {
  -webkit-transform-origin: 50%;
          transform-origin: 50%;
  -webkit-animation: rm-eclipse-animate-1 1.6s 0s 1 forwards;
          animation: rm-eclipse-animate-1 1.6s 0s 1 forwards
}

@-webkit-keyframes rm-eclipse-animate-1 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 0;
  }
}

@keyframes rm-eclipse-animate-1 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 0;
  }
}

.rm-eclipse.show g:nth-child(2) {
  -webkit-transform-origin: 50%;
          transform-origin: 50%;
  -webkit-animation: rm-eclipse-animate-2 2.5s 0s 1 forwards;
          animation: rm-eclipse-animate-2 2.5s 0s 1 forwards;
}

@-webkit-keyframes rm-eclipse-animate-2 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }

  3% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.4;
  }

  6% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 1;
  }

  8% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.2;
  }

  9% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 1;
  }

  30% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}

@keyframes rm-eclipse-animate-2 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }

  3% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.4;
  }

  6% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 1;
  }

  8% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.2;
  }

  9% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 1;
  }

  30% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}

.rm-eclipse.show g:nth-child(3) {
  -webkit-transform-origin: 50%;
          transform-origin: 50%;
  -webkit-animation: rm-eclipse-animate-3 2.5s 0s 1 forwards;
          animation: rm-eclipse-animate-3 2.5s 0s 1 forwards;
}

@-webkit-keyframes rm-eclipse-animate-3 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  10% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  60% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes rm-eclipse-animate-3 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  10% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  60% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
