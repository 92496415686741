
.l-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 140px;
}

.l-lines > div {
  position: absolute;
  height: 3px;
  left:50%;
  top: 50%;
}

.l-lines g {
  opacity: 0;
}

.l-lines svg {
  position: absolute;
  width: 100%;
  height: 3px;
  left:0;
  top: 0;
}

.l-line-1 {
  width: 44px;
  height: 3px;
  margin-top: -16px;
  left: 104px;
}

.l-line-1.kick g
 { animation: l-line-animate-1 1s 0s 1 forwards; }

@keyframes l-line-animate-1 {
  0% { opacity: 1; transform: translateX(-100%); }
  30% { transform: translateX(0); }
  60% {  opacity: 1; }
  80% { transform: translateX(100%); opacity: 0; }
  80.01% { transform: translateX(-100%) scale(0.4); opacity: 1; }
  100% {transform: translateX(100%); }
}

.l-line-1a {
  width: 14px;
  height: 3px;
  margin:-16px 0 0 44px;
  transform: rotate(-45deg);
  transform-origin: 0;
}

.l-line-1a.kick g
 {  animation: l-line-animate-1a 1s 0s 1 forwards; }

@keyframes l-line-animate-1a {
  0% { transform: translateX(-100%) scaleX(0.4); opacity: 1; }
  30% { transform: translateX(-100%) scaleX(0.4);}
  50% { transform: translateX(0); }
  60% { transform: translateX(0); }
  100% {transform: translateX(100%); opacity: 1; }
}

.l-line-1b.kick {
  width: 20px;
  height: 3px;
  margin: -26px 0 0 53px;
  transform-origin: 0;
}

.l-line-1b.kick g
 { animation: l-line-animate-1b 1s 0.6s 1 forwards; }

@keyframes l-line-animate-1b {
  0% { opacity: 1; transform: translateX(-100%); }
  30% { transform: translateX(0); }
  80% {transform: translateX(100%);}
  100% {transform: translateX(100%); }
}

.l-line-2 {
  width: 44px;
  height: 3px;
  margin-top: -10px;
}

.l-line-2.kick g
 { animation: l-line-animate-2 1.2s 0.5s 1 forwards; }

@keyframes l-line-animate-2 {
  0% { transform: translateX(-100%) scaleX(0.4); opacity: 1;}
  20% {   transform: translateX(100%) scaleX(0.4); }
  20.1% { transform: translateX(-100%); }
  60% { opacity: 1; }
  60.5% { opacity: 0; }
  68% { opacity: 1; }
  68.5% { transform: translateX(10%); }
  70% { transform: translateX(50%); opacity: 1; }
  90% { transform: translateX(100%); }
  100% {transform: translateX(100%); opacity: 0;}
}

.l-line-2.next-kick g
 { animation: l-line-animate-2a 0.8s 0.2s 1 forwards; }

 @keyframes l-line-animate-2a {
   0% { transform: translateX(-100%) scaleX(0.4); opacity: 1;}
   20% {   transform: translateX(100%) scaleX(0.4); }
   20.1% { transform: translateX(-100%); }
   68.5% { transform: translateX(100%); opacity:0}
   100% {transform: translateX(100%); opacity: 0;}
 }

.l-line-3 {
  width: 60px;
  height: 3px;
  margin-top: -3px;
}

.l-line-3.kick g
 { animation: l-line-animate-3 1.2s 0s 1 forwards; }

@keyframes l-line-animate-3 {
  0% { transform: translateX(-100%) scaleX(0.4); opacity: 1;}
  40% { transform: translateX(0) scaleX(1); }
  49% { opacity: 1; }
  49.5% { opacity: 0; }
  50% { opacity: 1; }
  50.5% { opacity: 0; }
  58% { opacity: 1; }
  70% {transform: translateX(100%) scaleX(0.6); }
  100% {transform: translateX(100%) scaleX(1); opacity: 0; }
}

.l-line-3.next-kick g
 { animation: l-line-animate-3c 0.5s 0s 1 forwards; }

@keyframes l-line-animate-3c {
  0% { transform: translateX(-100%) scaleX(0.4); opacity: 1; }
  100% {transform: translateX(100%) scaleX(1);  opacity: 1; }
}


.l-line-3a {
  width: 20px;
  height: 3px;
  margin: -3px 0 0 60px;
  transform-origin: 0;
  transform: rotate(45deg);
}

.l-line-3a.kick g
 { animation: l-line-animate-3a 1.2s 0.4s 1 forwards; }

@keyframes l-line-animate-3a {
  0% { transform: translateX(-100%); opacity: 1; }
  30% { transform: translateX(-30%); opacity: 1; }
  80% {transform: translateX(100%); opacity: 0; }
  100% {transform: translateX(100%); opacity: 0; }
}

.l-line-3a.next-kick g
 { animation: l-line-animate-3b 0.3s 0.3s 1 forwards; }

@keyframes l-line-animate-3b {
  0% { transform: translateX(-100%); opacity: 1; }
  100% { transform: translateX(100%); opacity: 0; }
}

.l-line-4 {
  width: 44px;
  height: 3px;
  margin: -16px 0 0 -44px;
}

.l-line-4.kick g
 { animation: l-line-animate-4 2s 0s 1 forwards; }

@keyframes l-line-animate-4 {
  0% { transform: translateX(100%) scaleX(0.4); opacity: 1; }
  5% { opacity: 1; }
  5.5% { opacity: 0; }
  6% { opacity: 1; }
  10% { transform: translateX(0) scaleX(1); opacity: 1;}
  50% { opacity: 1; }
  50.5% { opacity: 0; }
  58% { opacity: 1; }
  60% { transform: translateX(-80%) scaleX(1); opacity:1; }
  100% {transform: translateX(-100%);  opacity: 0; }
}

.l-line-4.next-kick g
 { animation: l-line-animate-4c 1s 0s 1 forwards; }

@keyframes l-line-animate-4c {
  0% { transform: translateX(100%) scaleX(0.4); opacity: 1; }
  5% { opacity: 1; }
  5.5% { opacity: 0; }
  6% { opacity: 1; }
  10% { transform: translateX(0) scaleX(1); opacity: 1;}
  50% { opacity: 1; }
  50.5% { opacity: 0; }
  58% { opacity: 1; }
  60% { transform: translateX(-80%) scaleX(1); opacity:1; }
  100% {transform: translateX(-100%);  opacity: 0; }
}

.l-line-4a {
  width: 14px;
  height: 3px;
  margin:-16px 0 0 -58px;
  transform: rotate(45deg);
  transform-origin: 100%;
}

.l-line-4a.kick g
 { animation: l-line-animate-4a 2s 0s 1 forwards; }

@keyframes l-line-animate-4a {
  0% { transform: translateX(100%) scaleX(0.4); opacity: 1; }
  10% { transform: translateX(100%) scaleX(0.4); }
  30% { transform: translateX(0); opacity: 1; }
  90% { transform: translateX(0); opacity: 0; }
  100% {transform: translateX(-100%); opacity: 0; }
}

.l-line-4b {
  width: 20px;
  height: 3px;
  margin: -26px 0 0 -73px;
}

.l-line-4b.kick g
 { animation: l-line-animate-4b 2s 0.5s 1 forwards; }

@keyframes l-line-animate-4b {
 0% { opacity: 1; transform: translateX(100%); }
 10% { transform: translateX(0); }
 40% {transform: translateX(-100%);}
 100% {opacity: 1; transform: translateX(-100%);  }
}

.l-line-5.kick {
  width: 44px;
  height: 3px;
  margin: -10px 0 0 -44px;
}

.l-line-5.kick g
 { animation: l-line-animate-5 1.2s 0.2s 1 forwards; }

@keyframes l-line-animate-5 {
  0% { opacity: 1; transform: translateX(100%) scaleX(0.4);}
  20% { transform: translateX(-100%) scaleX(0.4); }
  20.1% { transform: translateX(100%) scaleX(1); }
  60% { opacity: 1; }
  60.5% { opacity: 0; }
  68% { opacity: 1; }
  68.5% { transform: translateX(-10%) scaleX(0.4); }
 100% {transform: translateX(-100%); opacity: 0;}
}

.l-line-6.kick {
  width: 50px;
  height: 3px;
  margin: 0 0 0 -50px;
}

.l-line-6.kick g
 { animation: l-line-animate-6 1s 0s 1 forwards; }

@keyframes l-line-animate-6 {
  0% { transform: translateX(100%) scaleX(0.4); opacity: 1; }
  5% { opacity: 1; }
  5.5% { opacity: 0; }
  6% { opacity: 1; }
  10% { transform: translateX(0) scaleX(1); opacity: 1;}
  50% { opacity: 1; }
  50.5% { opacity: 0; }
  58% { opacity: 1; }
  60% { transform: translateX(-80%) scaleX(1); opacity:1; }
  100% {transform: translateX(-100%);  opacity: 0; }
}
