.pp-year {
  position: absolute;
  width: 70px;
  height: 20px;
  top: -10px;
  right: -70px;
  opacity: 0;
}

.pp-year.show {
  opacity: 1;
  -webkit-transition: opacity 0.8s 1s;
  transition: opacity 0.8s 1s;
}

.pp-year-numbs-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}

.pp-year-numbs {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
}

.pp-year-numbs>div {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  width: 10px;
  height: 100%;
  overflow: hidden;
}

.pp-year-numb>div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100%;
  left: 0;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

.pp-year-numb:nth-child(1) > div:nth-child(1).show {
  -webkit-animation: pp-year-numb1-animate1 0.3s 1s 1 forwards;
          animation: pp-year-numb1-animate1 0.3s 1s 1 forwards;
}
.pp-year-numb:nth-child(2) > div:nth-child(1).show {
  -webkit-animation: pp-year-numb2-animate1 0.1s 1s 1 forwards;
          animation: pp-year-numb2-animate1 0.1s 1s 1 forwards;
}
.pp-year-numb:nth-child(2) > div:nth-child(2).show {
  -webkit-animation: pp-year-numb2-animate1 0.12s 1.06s 1 forwards;
          animation: pp-year-numb2-animate1 0.12s 1.06s 1 forwards;
}
.pp-year-numb:nth-child(2) > div:nth-child(3).show {
  -webkit-animation: pp-year-numb2-animate1 0.12s 1.1s 1 forwards;
          animation: pp-year-numb2-animate1 0.12s 1.1s 1 forwards;
}
.pp-year-numb:nth-child(2) > div:nth-child(4).show {
  -webkit-animation: pp-year-numb2-animate2 0.2s 1.2s 1 forwards;
          animation: pp-year-numb2-animate2 0.2s 1.2s 1 forwards;
}
@keyframes pp-year-numb1-animate1 {
  0% { top: 100%;} 100% { top: 0; }
}
@keyframes pp-year-numb2-animate1 {
  0% { top: 100%; opacity: 1; } 100% { top: 0; opacity: 0; }
}
@keyframes pp-year-numb2-animate2 {
  0% { top: 100%; } 100% { top: 0 }
}

.pp-year-numb:nth-child(3)>div:nth-child(1).show {
  -webkit-animation: pp-year-numb3-animate1 0.1s 1s 1 forwards;
          animation: pp-year-numb3-animate1 0.1s 1s 1 forwards;
}
.pp-year-numb:nth-child(3)>div:nth-child(2).show {
  -webkit-animation: pp-year-numb3-animate1 0.12s 1.06s 1 forwards;
          animation: pp-year-numb3-animate1 0.12s 1.06s 1 forwards;
}
.pp-year-numb:nth-child(3)>div:nth-child(3).show {
  -webkit-animation: pp-year-numb3-animate2 0.15s 1.15s 1 forwards;
          animation: pp-year-numb3-animate2 0.15s 1.15s 1 forwards;
}
.pp-year-numb:nth-child(3)>div:nth-child(4).show {
  -webkit-animation: pp-year-numb3-animate2 0.16s 1.2s 1 forwards;
          animation: pp-year-numb3-animate2 0.16s 1.2s 1 forwards;
}
.pp-year-numb:nth-child(3)>div:nth-child(5).show {
  -webkit-animation: pp-year-numb3-animate3 0.25s 1.3s 1 forwards;
          animation: pp-year-numb3-animate3 0.25s 1.3s 1 forwards;
}
@keyframes pp-year-numb3-animate1 {
  0% { top: 100%; opacity: 1; } 100% { top: 0; opacity: 0; }
}
@keyframes pp-year-numb3-animate2 {
  0% { top: 100%; } 100% { top: -100%; }
}
@keyframes pp-year-numb3-animate3 {
  0% { top: 100%; } 100% { top: 0; }
}

.pp-year-numb:nth-child(4)>div:nth-child(1).show {
  -webkit-animation: pp-year-numb4-animate1 0.1s 1s 1 forwards;
          animation: pp-year-numb4-animate1 0.1s 1s 1 forwards;
}
.pp-year-numb:nth-child(4)>div:nth-child(2).show {
  -webkit-animation: pp-year-numb4-animate1 0.12s 1.06s 1 forwards;
          animation: pp-year-numb4-animate1 0.12s 1.06s 1 forwards;
}
.pp-year-numb:nth-child(4)>div:nth-child(3).show {
  -webkit-animation: pp-year-numb4-animate2 0.15s 1.15s 1 forwards;
          animation: pp-year-numb4-animate2 0.15s 1.15s 1 forwards;
}
.pp-year-numb:nth-child(4)>div:nth-child(4).show {
  -webkit-animation: pp-year-numb4-animate2 0.16s 1.27s 1 forwards;
          animation: pp-year-numb4-animate2 0.16s 1.27s 1 forwards;
}
.pp-year-numb:nth-child(4)>div:nth-child(5).show {
  -webkit-animation: pp-year-numb4-animate2 0.18s 1.35s 1 forwards;
          animation: pp-year-numb4-animate2 0.18s 1.35s 1 forwards;
}
.pp-year-numb:nth-child(4)>div:nth-child(6).show {
  -webkit-animation: pp-year-numb4-animate2 0.2s 1.4s 1 forwards;
          animation: pp-year-numb4-animate2 0.2s 1.4s 1 forwards;
}
.pp-year-numb:nth-child(4)>div:nth-child(7).show {
  -webkit-animation: pp-year-numb4-animate3 0.3s 1.6s 1 forwards;
          animation: pp-year-numb4-animate3 0.3s 1.6s 1 forwards;
}
@-webkit-keyframes pp-year-numb4-animate1 {
  0% { top: 100%; opacity: 1; } 100% { top: 0; opacity: 0; }
}
@keyframes pp-year-numb4-animate1 {
  0% { top: 100%; opacity: 1; } 100% { top: 0; opacity: 0; }
}
@-webkit-keyframes pp-year-numb4-animate2 {
  0% { top: 100%; opacity: 1; } 100% { top: -100%; opacity: 0.6; }
}
@keyframes pp-year-numb4-animate2 {
  0% { top: 100%; opacity: 1; } 100% { top: -100%; opacity: 0.6; }
}
@-webkit-keyframes pp-year-numb4-animate3 {
  0% { top: 100%; } 100% { top: 0; }
}
@keyframes pp-year-numb4-animate3 {
  0% { top: 100%; } 100% { top: 0; }
}
