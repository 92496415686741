.ip-text-2 {
  display: inline-block;
  font-size: 26px;
  font-weight: lighter;
  padding: 6px 8% 0 8%;
  opacity: 0;
}

.ip-text-2.lessWidth500 {
  font-size: 18px;
}

.ip-line-1,
.ip-line-2 {
  position: relative;
  margin: 0 auto;
  width: 280px;
  height: 3px;
}

.ip-text-2.show {
  opacity: 1; transition: opacity 1s 0.4s;
}

.ip-line-1 g  {
  transform-origin: 0; -webkit-transform-origin: 0;
  -webkit-transform: translateX(100%); transform: translateX(100%);
}

.ip-line-1 g.show {
  -webkit-animation: ip-line1-show-animate 1.5s 0.5s 1 forwards; animation: ip-line1-show-animate 1s 0.5s 1 forwards;
 }

 @-webkit-keyframes ip-line1-show-animate {
   0%{ -webkit-transform: translateX(100%) scaleX(0.2); transform: translateX(100%) scaleX(0.2); }
   40%{ -webkit-transform: translateX(-100%) scaleX(0.1); transform: translateX(-100%) scaleX(0.1); }
   40.001%{ -webkit-transform: translateX(100%) scaleX(1); transform: translateX(100%) scaleX(1); }
   80%{ -webkit-transform: translateX(10%) scaleX(1); transform: translateX(10%) scaleX(1); }
   100%{ -webkit-transform: translateX(0) scaleX(1); transform: translateX(0) scaleX(1); }
 }

 @keyframes ip-line1-show-animate {
   0%{ -webkit-transform: translateX(100%) scaleX(0.2); transform: translateX(100%) scaleX(0.2); }
   50%{ -webkit-transform: translateX(-100%) scaleX(0.1); transform: translateX(-100%) scaleX(0.1); }
   50.001%{ -webkit-transform: translateX(100%) scaleX(1); transform: translateX(100%) scaleX(1); }
   80%{ -webkit-transform: translateX(5%) scaleX(1); transform: translateX(5%) scaleX(1); }
   100%{ -webkit-transform: translateX(0) scaleX(1); transform: translateX(0) scaleX(1); }
 }

.ip-line-1 g.hide {
  -webkit-animation: ip-line1-hide-animate 0.8s 0.2s 1 forwards; animation: ip-line1-hide-animate 0.8s 0s 1 forwards;
 }

@-webkit-keyframes ip-line1-hide-animate {
  0%{ -webkit-transform: translateX(0); transform: translateX(0); }
  20%{ -webkit-transform: translateX(-80%); transform: translateX(-80%); }
  100%{ -webkit-transform: translateX(-100%); transform: translateX(-100%); }
}

@keyframes ip-line1-hide-animate {
  0%{ -webkit-transform: translateX(0); transform: translateX(0); }
  20%{ -webkit-transform: translateX(-80%); transform: translateX(-80%); }
  100%{ -webkit-transform: translateX(-100%); transform: translateX(-100%); }
}

.ip-line-2 g  {
  -webkit-transform: translateX(100%); transform: translateX(100%);
 }

.ip-line-2 g.show {
  -webkit-animation: ip-line2-show-animate 0.8s 1s 1 forwards; animation: ip-line2-show-animate 0.8s 0.5s 1 forwards;
}

@-webkit-keyframes ip-line2-show-animate {
  0%{ -webkit-transform: translateX(100%); transform: translateX(100%); }
  100%{ -webkit-transform: translateX(0); transform: translateX(0); }
}

@keyframes ip-line2-show-animate {
  0%{ -webkit-transform: translateX(100%); transform: translateX(100%); }
  100%{ -webkit-transform: translateX(0); transform: translateX(0); }
}

.ip-line-2 g.hide {
  -webkit-animation: ip-line2-hide-animate 0.6s 0s 1 forwards; animation: ip-line2-hide-animate 0.6s 0s 1 forwards;
}

@-webkit-keyframes ip-line2-hide-animate {
  0%{ -webkit-transform: translateX(0) scale(1); transform: translateX(0) scale(1); }
  10%{ -webkit-transform: translateX(20%) scale(1); transform: translateX(20%) scale(1); }
  12%{ -webkit-transform: translateX(34%) scale(1); transform: translateX(34%) scale(1); }
  14%{ -webkit-transform: translateX(10%) scale(0.4); transform: translateX(10%) scale(0.4); }
  16%{ -webkit-transform: translateX(60%) scale(0.6); transform: translateX(60%) scale(0.6); }
  18%{ -webkit-transform: translateX(30%) scale(1); transform: translateX(30%) scale(1); }
  50%{ -webkit-transform: translateX(90%) scale(1); transform: translateX(90%) scale(1); }
  100%{ -webkit-transform: translateX(100%) scale(0.2); transform: translateX(100%) scale(0.2);; }
}
@keyframes ip-line2-hide-animate {
  0%{ -webkit-transform: translateX(0) scale(1); transform: translateX(0) scale(1); }
  10%{ -webkit-transform: translateX(20%) scale(1); transform: translateX(20%) scale(1); }
  12%{ -webkit-transform: translateX(34%) scale(1); transform: translateX(34%) scale(1); }
  14%{ -webkit-transform: translateX(10%) scale(0.4); transform: translateX(10%) scale(0.4); }
  16%{ -webkit-transform: translateX(60%) scale(0.6); transform: translateX(60%) scale(0.6); }
  18%{ -webkit-transform: translateX(30%) scale(1); transform: translateX(30%) scale(1); }
  50%{ -webkit-transform: translateX(90%) scale(1); transform: translateX(90%) scale(1); }
  100%{ -webkit-transform: translateX(100%) scale(0.2); transform: translateX(100%) scale(0.2);; }
}
