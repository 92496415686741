.proj-card {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 180px;
  height: 140px;
}

.proj-card.lessWidth600 {
  width: 140px;
  height: 140px;
}

.proj-card * {
  color: #fff
}

.proj-card-word-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -70px 0 0 -70px;
  width: 140px;
  height: 140px;
  line-height: 140px;
  font-size: 50px;
  overflow: hidden;
  opacity: 1;
  color: #f4f0f0;
}

.proj-card-word-1.flyOut {
  color: #ca3232;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 0.5s 0.3s;
  transition: all 0.5s 0.3s
}

.proj-card-word-1.flyBack {
  color: #ffffff;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.6s 0.3s;
  transition: all 0.6s 0.3s
}

.proj-card-word-1.hoverOn {
  color: #c32727;
  -webkit-filter: blur(3px);
  filter: blur(3px);
  -webkit-transform: scale(1.20);
  transform: scale(1.20);
  -webkit-transition: all 0.12s 0s;
  transition: all 0.12s 0s;
}

.proj-card-word-1.hoverOff {
  color: #f4f0f0;
  -webkit-filter: blur(0);
  filter: blur(0);
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: color 0.12s 0s;
  transition: color 0.12s 0s;
}

.proj-card-word-1.loadingBegin {
  color: #ca3232;
  -webkit-filter: blur(3px);
  filter: blur(3px);
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: color 0.1s 0s;
  transition: color 0.1s 0s;
}

.proj-card-word-2 {
  position: absolute;
  visibility: hidden;
  bottom: 46px;
  right: 25px;
  width: 40px;
  height: 40px;
  font-size: 15px;
  font-weight: bold;
  color: #ca3232;
  line-height: 40px;
  opacity: 1;
}

.proj-card:nth-child(2n) .proj-card-word-2 {
  bottom: 70px;
}

.proj-card-square {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -70px 0 0 -70px;
  width: 140px;
  height: 140px;
  line-height: 140px;
  font-size: 50px;
  background: rgba(0, 0, 0, 0);
}

.proj-card-square> :nth-child(1) {
  position: absolute;
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  opacity: 1;
  top: 0;
  right: 0;
  width: 0;
  height: 1px;
  background-color: #ca3232;
}

.proj-card-square> :nth-child(1).hoverOn {
  -webkit-animation: proj-card-sq-animate-1 0.36s 0s 1 forwards;
  animation: proj-card-sq-animate-1 0.36s 0s 1 forwards;
}

.proj-card-square> :nth-child(1).hoverOff {
  -webkit-animation: proj-card-sq-animate-1a 0.28s 0s 1 forwards;
  animation: proj-card-sq-animate-1a 0.28s 0s 1 forwards;
}

@-webkit-keyframes proj-card-sq-animate-1 {
  65% {
    right: -5px;
    width: 80%;
    opacity: 1;
  }

  100% {
    right: 0;
    width: 80%;
    opacity: 1;
  }
}

@keyframes proj-card-sq-animate-1 {
  65% {
    right: -5px;
    width: 80%;
    opacity: 1;
  }

  100% {
    right: 0;
    width: 80%;
    opacity: 1;
  }
}

@-webkit-keyframes proj-card-sq-animate-1a {
  100% {
    right: 100%;
    width: 0;
    opacity: 0;
  }
}

@keyframes proj-card-sq-animate-1a {
  100% {
    right: 100%;
    width: 0;
    opacity: 0;
  }
}

.proj-card-square> :nth-child(2) {
  position: absolute;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  opacity: 0;
  top: 0;
  right: 0;
  width: 3px;
  height: 20%;
  background-color: #ca3232;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.proj-card-square> :nth-child(2).hoverOn {
  -webkit-animation: proj-card-sq-animate-2 0.3s 0s 1 forwards;
  animation: proj-card-sq-animate-2 0.3s 0s 1 forwards;
}

.proj-card-square> :nth-child(2).hoverOff {
  -webkit-animation: proj-card-sq-animate-2a 0.3s 0s 1 forwards;
  animation: proj-card-sq-animate-2a 0.3s 0s 1 forwards;
}

@-webkit-keyframes proj-card-sq-animate-2 {
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
    height: 40%;
  }
}

@keyframes proj-card-sq-animate-2 {
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
    height: 40%;
  }
}

@-webkit-keyframes proj-card-sq-animate-2a {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
    height: 40%;
  }

  100% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
    opacity: 0;
    height: 0;
  }
}

@keyframes proj-card-sq-animate-2a {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
    height: 40%;
  }

  100% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
    opacity: 0;
    height: 0;
  }
}

.proj-card-square> :nth-child(3) {
  position: absolute;
  opacity: 0;
  top: 100%;
  left: 100%;
  width: 10%;
  height: 4px;
  background-color: #ca3232;
}

.proj-card-square> :nth-child(3).hoverOn {
  -webkit-animation: proj-card-sq-animate-3 0.24s 0s 1 forwards;
  animation: proj-card-sq-animate-3 0.24s 0s 1 forwards;
}

.proj-card-square> :nth-child(3).hoverOff {
  -webkit-animation: proj-card-sq-animate-3a 0.18s 0s 1 forwards;
  animation: proj-card-sq-animate-3a 0.18s 0s 1 forwards;
}

@-webkit-keyframes proj-card-sq-animate-3 {
  50% {
    left: -10px;
    width: 40%;
    opacity: 1;
  }

  100% {
    left: 0;
    width: 40%;
    opacity: 1;
  }
}

@keyframes proj-card-sq-animate-3 {
  50% {
    left: -10px;
    width: 40%;
    opacity: 1;
  }

  100% {
    left: 0;
    width: 40%;
    opacity: 1;
  }
}

@-webkit-keyframes proj-card-sq-animate-3a {
  0% {
    left: 0;
    width: 40%;
    opacity: 1;
  }

  100% {
    left: 100%;
    width: 10%;
    opacity: 0;
  }
}

@keyframes proj-card-sq-animate-3a {
  0% {
    left: 0;
    width: 40%;
    opacity: 1;
  }

  100% {
    left: 100%;
    width: 10%;
    opacity: 0;
  }
}

.proj-card-square> :nth-child(4) {
  position: absolute;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  opacity: 0;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: #ca3232;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.proj-card-square> :nth-child(4).hoverOn {
  -webkit-animation: proj-card-sq-animate-4 0.2s 0s 1 forwards;
  animation: proj-card-sq-animate-4 0.2s 0s 1 forwards;
}

.proj-card-square> :nth-child(4).hoverOff {
  -webkit-animation: proj-card-sq-animate-4a 0.2s 0s 1 forwards;
  animation: proj-card-sq-animate-4a 0.2s 0s 1 forwards;
}

@-webkit-keyframes proj-card-sq-animate-4 {
  50% {
    opacity: 0.4;
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    height: 50%;
    opacity: 1;
  }
}

@keyframes proj-card-sq-animate-4 {
  50% {
    opacity: 0.4;
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    height: 50%;
    opacity: 1;
  }
}

@-webkit-keyframes proj-card-sq-animate-4a {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    height: 50%;
    opacity: 1;
  }

  100% {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
    height: 10%;
    opacity: 0;
  }
}

@keyframes proj-card-sq-animate-4a {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    height: 50%;
    opacity: 1;
  }

  100% {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
    height: 10%;
    opacity: 0;
  }
}

.proj-card:nth-child(2n) .proj-card-square {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.proj-card-word-line {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -60px;
  width: 80px;
  height: 3px;
  overflow: hidden;
}

.proj-card-word-line.flyOut {
  -webkit-transform: translateX(-100px) scaleX(0);
  transform: translateX(-100px) scaleX(0);
  -webkit-transition: all 0.3s 0.1s;
  transition: all 0.3s 0.1s;
}

.proj-card-word-line.flyBack {
  -webkit-transform: translateX(0) scaleX(1);
  transform: translateX(0) scaleX(1);
}

.proj-card-word-line.hoverOn div {
  left: 100%;
  -webkit-transition: left 0.3s 0s;
  transition: left 0.3s 0s;
}

.proj-card-word-line.hoverOff div {
  left: 0;
  -webkit-transition: left 0.3s 0s;
  transition: left 0.3s 0s;
}

.proj-card-word-line.loadingBegin div {
  left: 100%;
  -webkit-transition: left 0.15s 0s;
  transition: left 0.15s 0s;
}

.proj-card-word-line div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.proj-card-word-line svg {
  position: absolute;
  top: 0;
  left: 0;
}

.proj-card:nth-child(3n) .proj-card-word-line {
  width: 50px;
  margin-left: -35px;
  top: 42%;
  left: 50%
}

.proj-card:nth-child(4n) .proj-card-word-line {
  width: 30px;
  margin-left: -30px;
  top: 55%;
  left: 70%;
}

.proj-card-loader {
  position: absolute;
  opacity: 0;
  top: 50%;
  left: 50%;
  margin: -70px 0 0 -70px;
  width: 140px;
  height: 140px;
  line-height: 140px;
  font-size: 50px;
}

.proj-card-loader.loadingBegin {
  opacity: 1
}

.proj-card-loader>div {
  position: absolute;
  top: 0;
  left: 0;
  height: 25%;
  width: 0;
  background: url(./media/ero_loader_1.gif) #ca3232;
  background-position: 0 0;
}

.proj-card-loader> :nth-child(1) {
  box-shadow: 0px 5px 14px -4px rgba(179, 30, 30, 0.81);
  -webkit-filter: blur(1px);
  filter: blur(1px);
}

.proj-card-loader> :nth-child(2) {
  top: 25%;
  background-position: 0 -35px;
  box-shadow: 0px 0px 10px 0px rgba(179, 30, 30, 0.64);
  -webkit-filter: blur(1px);
  filter: blur(1px);
}

.proj-card-loader> :nth-child(3) {
  top: 50%;
  background-position: 0 -70px;
  box-shadow: 0px 0px 10px 0px rgba(179, 30, 30, 0.64);
  -webkit-filter: blur(1px);
  filter: blur(1px);
}

.proj-card-loader> :nth-child(4) {
  top: 75%;
  background-position: 0 -105px;
  box-shadow: 0px -2px 10px 0px rgba(179, 30, 30, 0.64);
  -webkit-filter: blur(1px);
  filter: blur(1px);
}

.proj-card-loader.loadingBegin :nth-child(1) {
  -webkit-animation: proj-card-loader-animate-1 1.6s 0s infinite;
  animation: proj-card-loader-animate-1 1.6s 0s infinite;
}

.proj-card-loader.loadingBegin :nth-child(2) {
  -webkit-animation: proj-card-loader-animate-1 1.6s 0.4s infinite;
  animation: proj-card-loader-animate-1 1.6s 0.4s infinite;
}

.proj-card-loader.loadingBegin :nth-child(3) {
  -webkit-animation: proj-card-loader-animate-1 1.6s 0.8s infinite;
  animation: proj-card-loader-animate-1 1.6s 0.8s infinite;
}

.proj-card-loader.loadingBegin :nth-child(4) {
  -webkit-animation: proj-card-loader-animate-1 1.6s 1.2s infinite;
  animation: proj-card-loader-animate-1 1.6s 1.2s infinite;
}

.proj-card-loader.loadingBegin :nth-child(5) {
  -webkit-animation: proj-card-loader-animate-2 1.8s 0s infinite;
  animation: proj-card-loader-animate-2 1.8s 0s infinite;
}

.proj-card-loader.loadingBegin :nth-child(6) {
  -webkit-animation: proj-card-loader-animate-2 1.8s 2.3s infinite;
  animation: proj-card-loader-animate-2 1.8s 2.3s infinite;
}

@-webkit-keyframes proj-card-loader-animate-1 {
  0% {
    left: 0;
    width: 0;
    opacity: 0;
  }

  14% {
    width: 20%;
    opacity: 0.5;
  }

  40% {
    left: 0;
    width: 100%;
    opacity: 1;
  }

  50% {
    left: 0;
    width: 100%;
  }

  60% {
    left: 95%;
    width: 5%;
    opacity: 1
  }

  100% {
    left: 100%;
    width: 0;
    opacity: 0;
  }
}

@keyframes proj-card-loader-animate-1 {
  0% {
    left: 0;
    width: 0;
    opacity: 0;
  }

  14% {
    width: 20%;
    opacity: 0.5;
  }

  40% {
    left: 0;
    width: 100%;
    opacity: 1;
  }

  50% {
    left: 0;
    width: 100%;
  }

  60% {
    left: 95%;
    width: 5%;
    opacity: 1
  }

  100% {
    left: 100%;
    width: 0;
    opacity: 0;
  }
}

@-webkit-keyframes proj-card-loader-animate-2 {
  0% {
    left: 0;
    width: 2%;
    opacity: 0;
  }

  16% {
    width: 12%;
    left: 78%;
    opacity: 1
  }

  50% {
    width: 0;
    left: 110%;
    opacity: 0
  }

  100% {
    left: 110%;
    width: 0;
    opacity: 0;
  }
}

@keyframes proj-card-loader-animate-2 {
  0% {
    left: 0;
    width: 2%;
    opacity: 0;
  }

  16% {
    width: 12%;
    left: 78%;
    opacity: 1
  }

  50% {
    width: 0;
    left: 110%;
    opacity: 0
  }

  100% {
    left: 110%;
    width: 0;
    opacity: 0;
  }
}

.proj-card-loader> :nth-child(5) {
  top: 0;
  background: #b72323;
  box-shadow: none;
  -webkit-filter: 0;
  filter: 0;
}

.proj-card-loader> :nth-child(6) {
  top: 50%;
  background: #b72323;
  box-shadow: none;
  -webkit-filter: 0;
  filter: 0;
}

.stopAnimate {
  -webkit-animation: none;
  animation: none;
}

@media screen and (max-width:600px) {
  .proj-card {
    width: 150px;
    height: 140px;
  }
}

/* Animations */
@-webkit-keyframes proj-card-animate-11 {
  0% {
    left: 0;
    width: 0;
    opacity: 0;
  }

  14% {
    width: 20%;
    opacity: 0.5;
  }

  40% {
    left: 0;
    width: 100%;
    opacity: 1;
  }

  50% {
    left: 0;
    width: 100%;
  }

  60% {
    left: 95%;
    width: 5%;
    opacity: 1;
  }

  100% {
    left: 100%;
    width: 0;
    opacity: 0;
  }
}

@keyframes proj-card-animate-11 {
  0% {
    left: 0;
    width: 0;
    opacity: 0;
  }

  14% {
    width: 20%;
    opacity: 0.5;
  }

  40% {
    left: 0;
    width: 100%;
    opacity: 1;
  }

  50% {
    left: 0;
    width: 100%;
  }

  60% {
    left: 95%;
    width: 5%;
    opacity: 1;
  }

  100% {
    left: 100%;
    width: 0;
    opacity: 0;
  }
}

@-webkit-keyframes proj-card-animate-12 {
  0% {
    left: 0;
    width: 2%;
    opacity: 0;
  }

  16% {
    width: 12%;
    left: 78%;
    opacity: 1;
  }

  50% {
    width: 0;
    left: 110%;
    opacity: 0;
  }

  100% {
    left: 110%;
    width: 0;
    opacity: 0;
  }
}

@keyframes proj-card-animate-12 {
  0% {
    left: 0;
    width: 2%;
    opacity: 0;
  }

  16% {
    width: 12%;
    left: 78%;
    opacity: 1;
  }

  50% {
    width: 0;
    left: 110%;
    opacity: 0;
  }

  100% {
    left: 110%;
    width: 0;
    opacity: 0;
  }
}

@-webkit-keyframes proj-card-animate-13 {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }

  10% {
    -webkit-transform: scale(1.06);
    transform: scale(1.06);
    opacity: 1;
  }

  12% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  16% {
    -webkit-transform: scale(1.04);
    transform: scale(1.04);
  }

  20% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes proj-card-animate-13 {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }

  10% {
    -webkit-transform: scale(1.06);
    transform: scale(1.06);
    opacity: 1;
  }

  12% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  16% {
    -webkit-transform: scale(1.04);
    transform: scale(1.04);
  }

  20% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
