.pp-desc {
  position: relative;
  width: 100%;
  left: 0;
  top: 0;
  margin: 0 auto;
  min-height: 1px;
  opacity: 1;
  padding-bottom: 80px;
}

.pp-desc>div {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
}

.pp-desc-ico {
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.pp-desc-txt {
  position: relative;
  margin: 0 auto;
  font-size: 20px;
  padding: 0 60px;
}

.pp-desc-txt.lessWidth1000 {
  font-size: 18px;
  padding: 10px 60px 50px 60px;
}
