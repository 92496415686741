.proj-card-list {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  max-width: 1000px;
  min-height: 160px;
  box-sizing: border-box;
  padding: 50px 0 60px 0;
  overflow: hidden;
  opacity: 0;
}

.proj-card-list.lessWidth600 {
  padding: 20px 14px 30px 14px;
}

.proj-card-list.loopAnimate {
  opacity: 1;
}

.proj-card-list.firstAnimate {
  animation: pcl-animate-1 1.5s 1 forwards;
}
@keyframes pcl-animate-1 {
  0%{ opacity: 0; }
  100%{ opacity: 1; }
}
